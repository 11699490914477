import React from 'react';
import usePrefix from 'utils/usePrefix';
import Modal from 'components/Modal';
import Button from 'components/_Redesign/Button';
import { ButtonsContainer } from 'pages/Users/User/styles';

import {
  ChatRemovalDescription,
  ChatRemovalHeader,
  ChatRemovalIcon,
  ChatRemovalModalContentWrapper,
} from './styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onRemove: () => void;
}

const ChatRemovalModal: React.FC<Props> = ({ onClose, isOpen, onRemove }) => {
  const t = usePrefix('Chat');
  const tg = usePrefix('General');

  return (
    <Modal isOpen={isOpen} small centered>
      <ChatRemovalModalContentWrapper>
        <ChatRemovalIcon type="trash" width="24" height="24" />
        <ChatRemovalHeader>{t('chat_delete')}</ChatRemovalHeader>
        <ChatRemovalDescription>
          <p>{t('chat_delete_description')}</p>
        </ChatRemovalDescription>
        <ButtonsContainer justifyContent="center" alignItems="flex-start">
          <Button onClick={onClose} color="secondary" size="lg" label={tg('cancel')} />
          <Button onClick={onRemove} color="error" size="lg" label={tg('delete')} />
        </ButtonsContainer>
      </ChatRemovalModalContentWrapper>
    </Modal>
  );
};

export default ChatRemovalModal;
