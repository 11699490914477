import styled from 'styled-components';

import Button from 'components/_Redesign/Button';
import Icon from 'components/Icons';
import FlexDiv from 'components/FlexDiv';

import { NotificationType } from './types';
import { notificationType } from './colors';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--notification-txt);
  text-align: left;
  z-index: 1050;
  p {
    margin: 0;
  }
`;

export const Container = styled(FlexDiv)`
  position: relative;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  width: 280px;
  background: var(--notification-bg);
`;

export const IconBox = styled(FlexDiv)`
  flex: 0 0 24px;
  margin: 0 8px 0 0;
`;

export const Message = styled.div`
  flex: 1;
`;

export const ButtonClose = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-width: 0;
  background: var(--notification-bg);
  color: var(--notification-txt);
  box-shadow: none;
  outline: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover:not(:disabled),
    &:hover:not([disabled]) {
      background: var(--notification-bg--hover);
      color: var(--notification-txt--hover);
    }
  }
`;

export const ButtonCloseIcon = styled(Icon)`
  fill: currentColor;
`;

export const NotificationIconStyled = styled(Icon)<{ color: NotificationType }>`
  ${({ color }) => notificationType(color)};
`;
