import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';

import { ActionTypes } from 'App/types';
import { useApp } from 'App';
import usePrefix from 'utils/usePrefix';
import { useApi, getApiUrl } from 'utils/api/useApi';
import { api, PaginatedList, ResponseError } from 'utils/api';
import {
  ChatDetailsResponse,
  ChatViolenceStatsResponse,
  ReporterDataEditingHistoryResponse,
} from 'utils/api/chats';
import { findViolenceTypeKey } from 'utils/misc';
import { notificationTypes } from 'utils/constants';
import { GetUsersResponse } from 'utils/api/users';

import ToggleSwitchFormik from 'components/ToggleSwitchFormik';
import Loader from 'components/Loader';
import { customSelectStyles } from 'components/Select/styles';
import EmptyState from 'components/EmptyState';
import InputField from 'components/InputField';
import Button from 'components/_Redesign/Button';

import {
  DetailTitle,
  SwitchContainer,
  Label,
  ViolenceTypesList,
  TypeItem,
  Detail,
  PlusContainer,
  ActionsTakenDetails,
  ActionsTakenDetailsSwitch,
  ActionsTakenDetailsAuthor,
} from '../styles';
import MetaSection from '../MetaSection';

const url = getApiUrl('/interveners/me/chats');

interface IntervenerTypeOption {
  value: number;
  label: string;
}

interface Props {
  chatId: number;
  onClear: () => void;
  handleInactiveChat: (deactivation_time: string | null) => void;
  deactivationTime: string | null;
  dataViolence?: ChatViolenceStatsResponse;
  isLoadingViolence: boolean;
  disableViolenceSelect: boolean;
  openViolenceScreen: () => void;
  onViolenceSelect: (violenceId: number) => void;
  openNotesScreen: () => void;
  setReporterDataEditingHistory: (data: ReporterDataEditingHistoryResponse[]) => void;
}

interface FormValues {
  attention_required: boolean;
  accept_time: string;
  close_time: string;
}

const GeneralScreen: React.FC<Props> = ({
  chatId,
  onClear,
  handleInactiveChat,
  deactivationTime,
  dataViolence,
  isLoadingViolence,
  openViolenceScreen,
  onViolenceSelect,
  disableViolenceSelect,
  openNotesScreen,
  setReporterDataEditingHistory,
}) => {
  const t = usePrefix('Chat');
  const tv = usePrefix('Violence');
  const tg = usePrefix('General');
  const [firstLoad, setFirstLoad] = useState(true);
  // eslint-disable-next-line no-magic-numbers
  const [intervalId, setIntervalId] = useState(0);
  const intervalIdRef = useRef<number>();

  const [{ violenceTypes }, dispatch] = useApp();

  const REFRESH_INTERVAL = 30000;

  const { data, isLoading, fetchData } = useApi<ChatDetailsResponse>(`${url}/${chatId}`, {
    method: 'GET',
  });

  const { data: dataIntervener, isLoading: isLoadingIntervener } = useApi<
    PaginatedList<GetUsersResponse[]>
  >(`${url}/${chatId}/possibleInterveners`, {
    method: 'GET',
  });

  const initialValues: FormValues = {
    attention_required: data?.chat.attention_required || false,
    accept_time: dayjs(data?.chat.accept_time).format('YYYY-MM-DD'),
    close_time: dayjs(data?.chat.close_time).format('YYYY-MM-DD'),
  };

  useEffect(() => {
    intervalIdRef.current = intervalId;
  });

  useEffect(() => {
    setReporterDataEditingHistory(data?.chat.reporter_data_editing_history || []);
  });

  const dataPoll = async () => {
    try {
      const response: ChatDetailsResponse = await api(`${url}/${chatId}`, {
        method: 'GET',
      });

      if (!!response.chat.deactivation_time && !deactivationTime)
        handleInactiveChat(response.chat.deactivation_time);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleInterval = () => {
    if (intervalId) window.clearInterval(intervalId);
    const tempIntervalId = window.setInterval(() => {
      dataPoll();
    }, REFRESH_INTERVAL);
    setIntervalId(tempIntervalId);
  };

  useEffect(() => {
    handleInterval();

    return () => clearInterval(intervalIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (values: FormValues) => {
    try {
      const response = await api(`${url}/${chatId}`, {
        method: 'PUT',
        payload: { attention_required: values.attention_required },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        onClear();
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (data?.chat) {
      if (firstLoad) {
        setFirstLoad(false);
        return;
      }
      if (data.chat.deactivation_time) handleInactiveChat(data.chat.deactivation_time);
      handleInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleIntervenerAssign = async (value: IntervenerTypeOption) => {
    if (!value || !('value' in value)) {
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: 'GENERAL_ERROR', type: notificationTypes.error },
      });
      return;
    }
    try {
      const response = await api(`${url}/${chatId}/assignIntervener`, {
        method: 'POST',
        payload: { intervenerId: value.value },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleApplicationAcceptanceDate = async () => {
    try {
      const response = await api(`${url}/${chatId}/accept`, {
        method: 'POST',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const handleCaseCompletionDateChange = async () => {
    const apiUrl = data?.chat.close_time ? `${url}/${chatId}/reopen` : `${url}/${chatId}/close`;
    try {
      const response = await api(apiUrl, {
        method: 'POST',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <>
      {!isLoading && data?.chat ? (
        <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize={true}>
          {({ isSubmitting, submitForm }) => (
            <Form autoComplete="off">
              <MetaSection title={t('childs_report')}>
                <Detail>
                  <Label>{t('attention_required')}</Label>
                  <SwitchContainer onChange={() => submitForm()}>
                    <ToggleSwitchFormik
                      checked={data.chat.attention_required}
                      disabled={isSubmitting}
                      name="attention_required"
                    />
                  </SwitchContainer>
                </Detail>
                <DetailTitle>{t('assigned_to')}</DetailTitle>
                {!isLoadingIntervener ? (
                  <ReactSelect
                    onChange={(value) => handleIntervenerAssign(value as IntervenerTypeOption)}
                    options={
                      dataIntervener?.content.length
                        ? dataIntervener.content.map((intervener) => ({
                            value: intervener.id,
                            label: `${intervener.first_name} ${intervener.last_name}`,
                          }))
                        : []
                    }
                    styles={customSelectStyles}
                    value={
                      data.chat.assigned_intervener
                        ? {
                            label: `${data.chat.assigned_intervener.first_name} ${data.chat.assigned_intervener.last_name}`,
                            value: data.chat.assigned_intervener.id,
                          }
                        : undefined
                    }
                  />
                ) : (
                  <Loader label={t('fetching_interveners_list')} />
                )}
                <DetailTitle>{tg('school_code').toUpperCase()}</DetailTitle>
                <Detail transparent>
                  <Label>{data.school.code}</Label>
                </Detail>

                <ActionsTakenDetails>
                  <ActionsTakenDetailsSwitch>
                    <Label>{t('application_accepted').toUpperCase()}</Label>
                    <SwitchContainer onChange={() => handleApplicationAcceptanceDate()}>
                      <ToggleSwitchFormik
                        checked={!!data.chat.accept_time}
                        disabled={isSubmitting || !!data.chat.accept_time}
                        name="accept_chat"
                      />
                    </SwitchContainer>
                  </ActionsTakenDetailsSwitch>

                  <DetailTitle>{t('case_acceptation_date')}</DetailTitle>
                  <InputField
                    margin="0 0 15px 0"
                    name="accept_time"
                    autoFocus
                    type="date"
                    dimension="lg"
                    disabled={true}
                  />

                  <DetailTitle>{t('case_acceptation_author')}</DetailTitle>
                  <ActionsTakenDetailsAuthor>
                    {data.chat.status === 'ACCEPTED_BY_SYSTEM'
                      ? t('case_auto_acceptation')
                      : data.chat.accepted_by_intervener || '-'}
                  </ActionsTakenDetailsAuthor>
                </ActionsTakenDetails>

                <ActionsTakenDetails>
                  <ActionsTakenDetailsSwitch>
                    <Label>{t('application_closed').toUpperCase()}</Label>
                    <SwitchContainer onChange={() => handleCaseCompletionDateChange()}>
                      <ToggleSwitchFormik
                        checked={!!data.chat.close_time}
                        disabled={isSubmitting}
                        name="close_chat"
                      />
                    </SwitchContainer>
                  </ActionsTakenDetailsSwitch>

                  <DetailTitle>{t('case_completion_date')}</DetailTitle>
                  <InputField
                    margin="0 0 15px 0"
                    name="close_time"
                    autoFocus
                    type="date"
                    dimension="lg"
                    disabled={true}
                  />

                  <DetailTitle>{t('case_completion_author')}</DetailTitle>
                  <ActionsTakenDetailsAuthor>
                    {data?.chat?.closed_by_intervener || '-'}
                  </ActionsTakenDetailsAuthor>
                </ActionsTakenDetails>

                <DetailTitle>{t('reported_violence_types').toUpperCase()}</DetailTitle>
                {!isLoadingViolence && data.chat.violence_category_id ? (
                  <ViolenceTypesList>
                    <TypeItem key={data.chat.violence_category_id}>
                      <Label>
                        {tv(
                          `${violenceTypes.find(
                            (type) => type.id === data.chat.violence_category_id,
                          )?.key}`,
                        )}
                      </Label>
                    </TypeItem>
                  </ViolenceTypesList>
                ) : (
                  <EmptyState title={t('no_data')} />
                )}
              </MetaSection>
              <MetaSection
                iconType="plus"
                onClick={openViolenceScreen}
                title={t('classified_violence_types')}
              >
                {!isLoadingViolence && dataViolence?.intervener.length ? (
                  <ViolenceTypesList>
                    {dataViolence.intervener.map((violence) => (
                      <TypeItem key={violence.violenceId}>
                        <Label>{tv(findViolenceTypeKey(violenceTypes, violence.violenceId))}</Label>
                        <PlusContainer>
                          <Button
                            icon="plus"
                            onClick={() => {
                              !disableViolenceSelect && onViolenceSelect(violence.violenceId);
                            }}
                            isDisabled={disableViolenceSelect}
                            size="md"
                            color="text-primary"
                            variant="text"
                          />
                        </PlusContainer>
                      </TypeItem>
                    ))}
                  </ViolenceTypesList>
                ) : (
                  <EmptyState title={t('no_data')} />
                )}
              </MetaSection>
              <MetaSection title={t('notes')}>
                <Detail>
                  <Label>{t('open_notes_pane')}</Label>
                  <PlusContainer>
                    <Button
                      icon="pencil"
                      onClick={() => openNotesScreen()}
                      isDisabled={disableViolenceSelect}
                      color="text-primary"
                      size="md"
                      variant="text"
                    />
                  </PlusContainer>
                </Detail>
              </MetaSection>
            </Form>
          )}
        </Formik>
      ) : (
        <Loader label={t('fetching_meta_data')} />
      )}
    </>
  );
};

export default GeneralScreen;
