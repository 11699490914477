import React, { FC, memo, useMemo, useState } from 'react';

import { ChatMessageResponse } from 'utils/api/chats';
import { messageTypes } from 'utils/constants';

import { MessageAuthor, MessageContainer, MessageData, MessageWrapper } from './styles';
import MessageView from './MessageView';
import MessageEdit from './MessageEdit';
import { IS_INTERVENT_MESSAGE_CLASS, IS_USER_MESSAGE_CLASS } from './constants';

export interface MessageValue {
  editMode: boolean;
  msg: ChatMessageResponse;
  height?: number;
}

const Message: FC<{
  chatId: number;
  response: ChatMessageResponse;
  nickname: string;
}> = ({ chatId, response, nickname }) => {
  const [messageValue, setMessageValue] = useState<MessageValue>({
    editMode: false,
    msg: response,
  });
  const { intervener_response } = messageTypes;

  const extraClasses = useMemo(() => {
    let classes = '';

    if (messageValue.msg.type === intervener_response) {
      classes = `${classes} ${IS_INTERVENT_MESSAGE_CLASS}`;
    } else {
      classes = `${classes} ${IS_USER_MESSAGE_CLASS}`;
    }

    return classes;
  }, [messageValue.msg.type]);

  return (
    <MessageWrapper
      className={extraClasses}
      flexDirection={messageValue.msg.type === intervener_response ? 'row-reverse' : 'row'}
      alignItems="flex-end"
    >
      <MessageData>
        <MessageAuthor>
          {messageValue.msg.type === intervener_response ? response.author : nickname}
        </MessageAuthor>
        <MessageContainer>
          {messageValue.editMode ? (
            <MessageEdit
              chatId={chatId}
              messageValue={messageValue}
              setMessageValue={setMessageValue}
            />
          ) : (
            <MessageView chatId={chatId} msg={messageValue.msg} setMessageValue={setMessageValue} />
          )}
        </MessageContainer>
      </MessageData>
    </MessageWrapper>
  );
};

export default memo(Message);
