import styled, { css } from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import DetailsTitle from 'components/DetailsTitle';

export const Container = styled(FlexDiv)`
  overflow: auto;
  width: 100%;
  padding: 0 24px 24px 40px;

  & > div {
    padding-top: 20px;
  }
`;

export const TextData = styled.div`
  flex: 1;
  min-width: 0;
`;

const subtitleEmptyStyles = css`
  opacity: 0.2;
`;

export const Subtitle = styled.h3<{ empty?: boolean }>`
  margin: 0;
  font-size: 16px;
  color: var(--notice-txt--secondary);
  ${({ empty }) => empty && subtitleEmptyStyles};
`;

export const Title = styled(DetailsTitle)`
  font-size: 35px;
`;

export const Content = styled(FlexDiv)`
  width: 100%;
`;
