import React from 'react';
import { Formik } from 'formik';
import { find } from 'lodash/fp';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { GetUsersResponse, PostUserRequest } from 'utils/api/users';
import { userValidationSchema } from 'utils/validationSchema';
import { api, getApiUrl, ResponseError } from 'utils/api';
import { userRoles } from 'utils/userRoles';
import usePrefix from 'utils/usePrefix';
import { notificationTypes } from 'utils/constants';
import { languages } from 'utils/languages';

import Modal from 'components/Modal';

import Form from './Form';

const url = getApiUrl(`/users`);

interface Props {
  users: GetUsersResponse[];
  userId?: number;
  onClose: () => void;
  fetchData: () => Promise<any>;
  isOpen: boolean;
}

const DetailsScreen: React.FC<Props> = ({ users, userId, onClose, fetchData, isOpen }) => {
  const [, dispatch] = useApp();

  const ty = usePrefix('YupErrors');

  const initialValues: PostUserRequest = {
    role: userRoles.intervener,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    user_language: languages.pl,
  };

  const getUser = (id?: number): PostUserRequest =>
    find((user) => user.id === id, users) || initialValues;
  const isActive = !!userId && find((user) => user.id === userId, users)?.is_active;

  const submit = async (values: PostUserRequest) => {
    try {
      const response = await api(!userId ? url : `${url}/${userId}`, {
        method: userId ? 'PUT' : 'POST',
        payload: {
          ...values,
          phone: values.phone ? values.phone : null,
        },
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        await fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  const user = getUser(userId);
  if (!user.phone) user.phone = '';

  return (
    <Modal isOpen={isOpen} onClose={onClose} paddingTop="24px">
      <Formik
        initialValues={user}
        onSubmit={submit}
        validationSchema={() => userValidationSchema(ty)}
        enableReinitialize={true}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            onClose={onClose}
            values={values}
            userId={userId}
            isActive={isActive}
            fetchData={fetchData}
          />
        )}
      </Formik>
    </Modal>
  );
};

export default DetailsScreen;
