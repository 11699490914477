import styled from 'styled-components';

import { buttonStyles } from 'components/Navigation/styles';
import { ANIMATION } from 'styles/variables';

import FlexDiv from 'components/FlexDiv';

export const CtaUpload = styled.div`
  ${buttonStyles};
  padding: 0 20px;
  height: 36px;
  border-radius: 8px;
  text-align: center;
  border: 0;
  background: var(--cta--primary-bg);
  transition: background ${ANIMATION};
  opacity: 1;
`;

export const Wrapper = styled(FlexDiv)`
  position: relative;
  padding: 24px 0 0;
  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
    @media (hover: hover) {
      &:hover {
        + ${CtaUpload} {
          background: var(--cta--primary-bg--hover);
        }
      }
    }
  }
`;

export const Upload = styled.div`
  position: relative;
  overflow: hidden;
`;
