import React from 'react';

import { NotificationsListTypes } from 'App/reducer';

import Notification from './Notification';
import { Wrapper } from './styles';

const Notifications: React.FC<{ notifications: NotificationsListTypes[] }> = ({
  notifications,
}) => (
  <Wrapper>
    {notifications.map(({ notification, id }) => (
      <Notification key={`${id}${notification.code}`} notification={notification} id={id} />
    ))}
  </Wrapper>
);

export default Notifications;
