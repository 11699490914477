import { ChatType, MessageType } from 'utils/api/chats';
import { NoticeType } from 'utils/api/notice';
import { ChatListTypes } from 'utils/api';

const ANONYMOUS: ChatType = 'ANONYMOUS';
const REGULAR: ChatType = 'REGULAR';

export const chatTypes = {
  anonymous: ANONYMOUS,
  regular: REGULAR,
};

const ADVICE: NoticeType = 'ADVICE';
const INSTRUCTION: NoticeType = 'INSTRUCTION';

export const noticeTypes = {
  advice: ADVICE,
  instruction: INSTRUCTION,
};

const INTERVENER_RESPONSE: MessageType = 'INTERVENER_RESPONSE';
const REPORTER_MESSAGE: MessageType = 'REPORTER_MESSAGE';

export const messageTypes = {
  intervener_response: INTERVENER_RESPONSE,
  reporter_message: REPORTER_MESSAGE,
};

export type NotificationType = 'SUCCESS' | 'ERROR';

const SUCCESS: NotificationType = 'SUCCESS';
const ERROR: NotificationType = 'ERROR';

export const notificationTypes = {
  success: SUCCESS,
  error: ERROR,
};

const IMPORTANT: ChatListTypes = 'IMPORTANT';
const ASSIGNED: ChatListTypes = 'ASSIGNED';
const ALL: ChatListTypes = 'ALL';
const TAGGED: ChatListTypes = 'TAGGED';

export const chatlistTypes = {
  important: IMPORTANT,
  assigned: ASSIGNED,
  all: ALL,
  tagged: TAGGED,
};
