import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { useApp } from 'App';
import { findViolenceTypeKey } from 'utils/misc';
import usePrefix from 'utils/usePrefix';
import { AttachmentResponse } from 'utils/api/inbox';

import DetailsTitle from 'components/DetailsTitle';
import AttachmentsList from 'components/AttachmentsList';

import { Container, Subtitle, TextData, Title } from './styles';

interface NoticesProps {
  name: string;
  title: string;
  content: string;
  attachments: AttachmentResponse[];
  violenceId?: number;
}

const NoticeContent: React.FC<NoticesProps> = ({
  name,
  title,
  content,
  attachments,
  violenceId,
}) => {
  const cleanHTML = sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img', 'span']),
    allowedAttributes: {
      '*': ['style'],
      img: ['src', 'alt', 'width', 'height'],
    },
  });

  const tv = usePrefix('Violence');

  const [{ violenceTypes }] = useApp();

  return (
    <Container>
      <TextData>
        <DetailsTitle title={name} />
        {violenceId && <Subtitle>{tv(findViolenceTypeKey(violenceTypes, violenceId))}</Subtitle>}

        <Title title={title} />
        <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
      </TextData>
      {!!attachments?.length && <AttachmentsList attachments={attachments} paddingTop="0" />}
    </Container>
  );
};

export default NoticeContent;
