import React from 'react';

import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';
import usePrefix from 'utils/usePrefix';

import ReporterDataHistoryRow from 'pages/Chat/ChatPanel/ReporterDataHistoryRow';

import { ReporterHistoryContainer, ReporterHistoryNoDataText } from './styles';

const ReporterDataEditingHistory: React.FC<{
  reporterDataEditingHistory?: ReporterDataEditingHistoryResponse[];
}> = ({ reporterDataEditingHistory }) => {
  const t = usePrefix('Chat');

  return (
    <ReporterHistoryContainer flexDirection="column">
      <h3>{t('message_change_history')}</h3>
      {reporterDataEditingHistory && reporterDataEditingHistory.length > 0 ? (
        reporterDataEditingHistory.map((item, index) => (
          <ReporterDataHistoryRow key={index} reporterDataEditingHistory={item} />
        ))
      ) : (
        <ReporterHistoryNoDataText>{t('no_data')}</ReporterHistoryNoDataText>
      )}
    </ReporterHistoryContainer>
  );
};

export default ReporterDataEditingHistory;
