import React from 'react';
import dayjs from 'dayjs';

import { ReporterDataEditingHistoryResponse } from 'utils/api/chats';
import { DotSeparator } from 'pages/Chat/ChatMeta/GeneralScreen/styles';

import { ReporterDataHistoryRowStyled } from './styles';

const ReporterDataHistoryRow: React.FC<{
  reporterDataEditingHistory: ReporterDataEditingHistoryResponse;
}> = ({ reporterDataEditingHistory }) => {
  const formattedReporterChangeTime = dayjs(reporterDataEditingHistory.change_time).format(
    'YYYY-MM-DD HH:mm',
  );

  return (
    <ReporterDataHistoryRowStyled>
      {formattedReporterChangeTime || '-'}
      <DotSeparator />
      {reporterDataEditingHistory.name_and_last_name || '-'}
      <DotSeparator />
      {reporterDataEditingHistory.email || '-'}
    </ReporterDataHistoryRowStyled>
  );
};

export default ReporterDataHistoryRow;
