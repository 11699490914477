import styled from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import { screenSmAbove } from 'styles/breakpoinst';

export const PageTop = styled.header`
  padding: 16px 24px;
  width: 100%;
`;

export const PageContent = styled(FlexDiv)`
  padding: 16px 24px;
  width: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled(FlexDiv)`
  padding: 0;
  width: 100%;

  @media screen and (min-width: ${screenSmAbove}) {
    border-right: 1px solid var(--chat-list-border);
  }
`;

export const ActionsListStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export const ActionsListItemStyled = styled.div`
  min-width: 0;
`;
