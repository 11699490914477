import React from 'react';
import { Formik } from 'formik';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { ChatMessageResponse } from 'utils/api/chats';
import { notificationTypes } from 'utils/constants';
import { api, getApiUrl, ResponseError } from 'utils/api';
import usePrefix from 'utils/usePrefix';
import TextArea from 'components/TextArea';
import Button from 'components/_Redesign/Button';

import { MessageValue } from './Message';
import { MessagePatchControls, MessagePatchForm } from './styles';

const url = getApiUrl('/interveners/me/chats');

interface FormValues {
  message: string;
}

const MessageEdit: React.FC<{
  chatId: number;
  messageValue: MessageValue;
  setMessageValue: (set: MessageValue) => void;
  height?: number;
}> = ({ chatId, messageValue, setMessageValue }) => {
  const { msg, height } = messageValue;
  const [, dispatch] = useApp();
  const t = usePrefix('Chat');

  const submit = async (form: FormValues) => {
    try {
      const response: ChatMessageResponse = await api(
        `${url}/${chatId}/messages/text-message/${msg.id}`,
        {
          method: 'PATCH',
          payload: {
            ...form,
          },
        },
      );
      if (response) {
        setMessageValue({ editMode: false, msg: response });
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Formik initialValues={{ message: msg.message ? msg.message : '' }} onSubmit={submit}>
      {({ isSubmitting }) => (
        <MessagePatchForm>
          <TextArea
            height={height ? height + 'px' : '100px'}
            cols={100}
            name="message"
            placeholder={t('type_message')}
            maxLength={1000}
          />
          <MessagePatchControls>
            <Button
              icon="close"
              isDisabled={isSubmitting}
              onClick={() => setMessageValue({ editMode: false, msg })}
              size="md"
              color="text-error"
              variant="text"
            />
            <Button
              type="submit"
              icon={isSubmitting ? 'paperPlane' : 'paperPlaneFill'}
              isDisabled={isSubmitting}
              onClick={() => setMessageValue({ editMode: false, msg })}
              size="md"
              color="text-primary"
              variant="text"
            />
          </MessagePatchControls>
        </MessagePatchForm>
      )}
    </Formik>
  );
};

export default MessageEdit;
