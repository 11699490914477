import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { find } from 'lodash/fp';
import * as Yup from 'yup';

import { api, getApiUrl, PaginatedList, ResponseError } from 'utils/api';
import { NoticeType, AttachmentResponse, NoticeListResponse } from 'utils/api/notice';
import { noticeTypes, notificationTypes } from 'utils/constants';
import { SchoolResponse } from 'utils/api/schools';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import usePrefix from 'utils/usePrefix';

import Modal from 'components/Modal';

import Form from './Form';
import Target from './Target';

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    title: Yup.string().required(ty('field_required')),
    name: Yup.string().required(ty('field_required')),
    content: Yup.string().required(ty('field_required')),
  });

const url = getApiUrl('/notices/type');

interface Props {
  notices: NoticeListResponse[];
  noticeId: number | undefined;
  onClose: () => void;
  fetchData: () => Promise<PaginatedList<NoticeListResponse[]> | void>;
  isOpen: boolean;
}

export interface FormValues {
  type: NoticeType;
  title: string;
  name: string;
  content: string;
  violenceId?: number;
  attachments?: AttachmentResponse[];
  sent_to_schools?: SchoolResponse[] | number[];
  valid_licence?: boolean;
}

const Notice: React.FC<Props> = ({ notices, noticeId, onClose, isOpen, fetchData }) => {
  const [, dispatch] = useApp();

  const ty = usePrefix('YupErrors');

  const [refetchDetails, setRefetchDetails] = useState(false);
  const [noticeDetails, setNoticeDetails] = useState<FormValues>();
  const initialValues: FormValues = {
    type: noticeTypes.advice,
    title: '',
    name: '',
    content: '',
  };

  const handleRefetchDetails = (val: boolean) => setRefetchDetails(val);

  const getNotice = (id?: number) => find((notice) => notice.id === id, notices);

  const loadData = async () => {
    const notice = getNotice(noticeId);
    if (!notice) return;
    try {
      const response = await api(`${url}/${notice.type}/${noticeId}`, {
        method: 'GET',
      });

      if (response.details) {
        setNoticeDetails({
          ...response.details,
          // eslint-disable-next-line no-magic-numbers
          violenceId: response.violenceId || 0,
          type: notice.type,
          sent_to_schools: response.sent_to_schools,
        });
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (noticeId) {
      loadData();
      return;
    }
    setNoticeDetails(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!refetchDetails) return;
    loadData();
    handleRefetchDetails(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchDetails]);

  const submit = async (values: FormValues) => {
    const payload = values.violenceId
      ? {
          details: { content: values.content, name: values.name, title: values.title },
          violenceId: values.violenceId,
          sent_to_schools: values.sent_to_schools,
        }
      : { ...values };
    try {
      const response = await api(
        !noticeId ? `${url}/${values.type}` : `${url}/${values.type}/${noticeId}`,
        {
          method: noticeId ? 'PUT' : 'POST',
          payload,
        },
      );

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  if (!noticeDetails) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} paddingTop="24px">
      <Formik
        initialValues={noticeDetails}
        onSubmit={submit}
        enableReinitialize={true}
        validationSchema={() => validationSchema(ty)}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue, dirty }) => (
          <>
            <Form
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              onClose={onClose}
              values={values}
              setFieldValue={setFieldValue}
              noticeId={noticeId}
              handleRefetchDetails={handleRefetchDetails}
            />
            {!!noticeId && (
              <Target
                noticeDetails={noticeDetails}
                noticeId={noticeId}
                onClose={onClose}
                fetchData={fetchData}
                isNoticeChanged={dirty}
              />
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default Notice;
