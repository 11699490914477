import React, { useState, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import dayjs from 'dayjs';

import { getApiUrl, PaginatedList } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import usePrefix from 'utils/usePrefix';
import { ReceivedAdviceResponse } from 'utils/api/inbox';

import NoticeContainer from 'components/NoticeContainer';
import ListContainer from 'components/ListContainer';
import RowShort from 'components/RowShort';
import EmptyState from 'components/EmptyState';
import { ListWrapper } from 'components/ListContainer/styles';

import Advice from './Advice';

const url = getApiUrl('/interveners/me/advices');

const RECORD_COUNT = 9999999;

const Advices: React.FC = () => {
  const t = usePrefix('Advices');

  const [adviceId, setAdviceId] = useState<number | undefined>(undefined);
  const [nameFilter, setNameFilter] = useState('');

  const MIN_QUERY_LENGTH = 3;

  const { data, fetchData } = useApi<PaginatedList<ReceivedAdviceResponse[]>>(
    nameFilter.length < MIN_QUERY_LENGTH
      ? `${url}`
      : `${url}?page=0&size=${RECORD_COUNT}&name=${nameFilter}`,
    {
      method: 'GET',
    },
  );

  const handleFilterReset = () => {
    setNameFilter('');
  };

  useEffect(() => {
    if (!!data?.content.length && !adviceId) setAdviceId(data.content[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) return null;

  return (
    <NoticeContainer>
      <ListContainer
        title={t('advices_list')}
        setFilter={setNameFilter}
        filter={nameFilter}
        resetFilter={handleFilterReset}
        isSearchClearDisabled={nameFilter.length < 1}
      >
        {data.content.length > 0 && (
          <ListWrapper>
            <AutoSizer disableWidth>
              {({ height }: { height: number }) => (
                <List height={height} width="100%" itemCount={data.content.length} itemSize={64}>
                  {({ index, style }) => (
                    <RowShort
                      title={data.content[index].name}
                      subtitle={`${dayjs(data.content[index].receivedDate).format(
                        'DD-MM-YYYY',
                      )} / ${
                        data.content[index].readDate
                          ? dayjs(data.content[index].readDate).format('DD-MM-YYYY')
                          : t('not_read')
                      }`}
                      onClick={() => setAdviceId(data.content[index].id)}
                      unread={!data.content[index].readDate}
                      selected={!!adviceId && data.content[index].id === adviceId}
                      style={style}
                    />
                  )}
                </List>
              )}
            </AutoSizer>
          </ListWrapper>
        )}
        {!adviceId && <EmptyState title={t('no_advices')} />}
      </ListContainer>
      {!adviceId ? (
        <EmptyState title={t('no_advices')} />
      ) : (
        <Advice adviceId={adviceId} fetchData={fetchData} />
      )}
    </NoticeContainer>
  );
};

export default Advices;
