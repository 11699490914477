import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import { CreateSchoolRequest } from 'utils/api/schools';
import { languages } from 'utils/languages';

import Button from 'components/_Redesign/Button';
import DetailsTitle from 'components/DetailsTitle';
import FlexDiv from 'components/FlexDiv';
import ToggleSwitchFormik from 'components/ToggleSwitchFormik';
import RadioInput from 'components/RadioInput';

import {
  ButtonsContainer,
  Content,
  FieldsContainer,
  FormSpacer,
  LanguagesContainer,
  StyledFormField,
  StyledFullField,
  StyledInputField,
  Subtitle,
  SwitchContainer,
  SwitchLabel,
  TextData,
} from './styles';

interface Props {
  onClose: () => void;
  isNew: boolean;
}

const DetailsForm: React.FC<
  Pick<FormikProps<CreateSchoolRequest>, 'errors' | 'touched' | 'isSubmitting' | 'values'> & Props
> = ({ errors, touched, isSubmitting, onClose, isNew, values, ...props }) => {
  const t = usePrefix('General');

  return (
    <Content {...props} autoComplete="off">
      <FlexDiv justifyContent="space-between" alignItems="center">
        <TextData>
          {/* eslint-disable-next-line no-magic-numbers */}
          <DetailsTitle title={values.name || t('school_name')} empty={values.name.length < 1} />
          {/* eslint-disable-next-line no-magic-numbers */}
          <Subtitle empty={values.locality.length < 1}>{values.locality || t('locality')}</Subtitle>
        </TextData>
        <ButtonsContainer alignItems="center">
          <Button
            onClick={onClose}
            color="text-primary"
            size="lg"
            variant="text"
            label={t('dont_save_changes')}
          />
          <Button
            type="submit"
            label={t('save_changes')}
            color="primary"
            size="lg"
            isDisabled={isSubmitting}
          />
        </ButtonsContainer>
      </FlexDiv>
      <FieldsContainer>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="name"
            error={touched.name && errors.name}
            label={t('school_name')}
          />
          <StyledInputField
            name="voivodeship"
            error={touched.voivodeship && errors.voivodeship}
            label={t('voivodeship')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="street"
            error={touched.street && errors.street}
            label={t('street')}
          />
          <StyledInputField
            name="number"
            error={touched.number && errors.number}
            label={t('st_number')}
          />
        </FlexDiv>
        <FlexDiv justifyContent="space-between" alignItems="flex-start">
          <StyledInputField
            name="postal_code"
            error={touched.postal_code && errors.postal_code}
            label={t('postal_code')}
          />
          <StyledInputField
            name="locality"
            error={touched.locality && errors.locality}
            label={t('locality')}
          />
        </FlexDiv>
        <SwitchContainer justifyContent="stretch" flexDirection="column">
          <SwitchLabel>{t('name_and_email_mandatory')}</SwitchLabel>
          <ToggleSwitchFormik
            checked={values.name_and_mail_mandatory}
            disabled={isSubmitting}
            name="name_and_mail_mandatory"
          />
        </SwitchContainer>
        <FlexDiv justifyContent="stretch" alignItems="flex-start">
          <StyledFullField
            name="chat_case_number_mask"
            error={touched.chat_case_number_mask && errors.chat_case_number_mask}
            label={t('chat_case_number_mask')}
            placeholder={t('chat_case_number_mask_placeholder')}
          />
        </FlexDiv>
        <LanguagesContainer>
          <StyledFormField label={t('language')}>
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="PL"
              value={languages.pl}
            />
            <RadioInput
              name="school_language"
              error={touched.school_language && !!errors.school_language}
              label="EN"
              value={languages.en}
            />
          </StyledFormField>
        </LanguagesContainer>
        {!isNew && (
          <>
            <FormSpacer />
            <FlexDiv justifyContent="stretch" alignItems="flex-start">
              <StyledFullField
                name="term_of_use"
                error={touched.term_of_use && errors.term_of_use}
                label={t('term_of_use')}
              />
            </FlexDiv>
          </>
        )}
      </FieldsContainer>
    </Content>
  );
};

export default DetailsForm;
