import React, { FC, memo, ReactNode } from 'react';

import DataRow from 'components/DataRow';
import DataCell from 'components/DataCell';

interface Props {
  clickable?: boolean;
  labels: string[];
  title: string;
  selected?: boolean;
  disabled?: boolean;
  unread?: boolean;
  cellChildren?: ReactNode;
  onClick?: () => void;
}

const Row: FC<Props> = ({
  onClick,
  disabled,
  clickable = true,
  labels,
  cellChildren,
  ...props
}) => {
  // eslint-disable-next-line no-magic-numbers
  const cols = labels.length + (cellChildren ? 1 : 0);
  return (
    <DataRow
      clickable={clickable}
      onClick={() => !disabled && !!onClick && onClick()}
      disabled={disabled}
      {...props}
    >
      {labels.map((label, i) => (
        <DataCell key={`${label}_${i}`} cols={cols} label={label} />
      ))}
      {cellChildren && <DataCell cols={cols}>{cellChildren}</DataCell>}
    </DataRow>
  );
};

export default memo(Row);
