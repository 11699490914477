import React, { FC } from 'react';

import { BouncingDotStyled, LoaderBouncingDotsStyled } from './styles';

/* TODO -> ten komponent nie jest używany */
const LoaderBouncingDots: FC = () => (
  <LoaderBouncingDotsStyled>
    <BouncingDotStyled />
    <BouncingDotStyled />
    <BouncingDotStyled />
  </LoaderBouncingDotsStyled>
);

export default LoaderBouncingDots;
