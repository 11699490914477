import React from 'react';

import { AttachmentResponse } from 'utils/api/inbox';
import usePrefix from 'utils/usePrefix';

import { Attachments, AttachmentTitle, AttachList, AttachItem, Anchor, IconStyled } from './styles';

interface NoticesProps {
  attachments: AttachmentResponse[];
  paddingTop?: string;
}

const AttachmentsList: React.FC<NoticesProps> = ({ attachments, paddingTop = '24px' }) => {
  const t = usePrefix('Notices');
  return (
    <Attachments flex={1} flexWrap="wrap" flexDirection="column" paddingTop={paddingTop}>
      <AttachmentTitle title={t('attachments')} marginBottom="25px" />
      <AttachList>
        {attachments.map((att) => (
          <AttachItem key={att.uri}>
            <Anchor href={`/${att.uri}`} target="_blank">
              {att.name}
            </Anchor>
            <IconStyled type="paperclip" width="20" height="20" />
          </AttachItem>
        ))}
      </AttachList>
    </Attachments>
  );
};

export default AttachmentsList;
