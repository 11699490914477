export const APP_URL = 'app';
export const SET_PASSWORD_URL = 'set-password';
export const RESET_PASSWORD_URL = 'reset-password';
export const REPORTS_URL = 'reports';
export const CHAT_URL = 'chat';
export const USERS_URL = 'users';
export const SCHOOLS_URL = 'schools';
export const PROFILE_URL = 'profile';
export const NOTICES_URL = 'notices';
export const ADVICES_URL = 'advices';
export const INSTRUCTIONS_URL = 'instructions';
