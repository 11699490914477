import React from 'react';
import dayjs from 'dayjs';

import usePrefix from 'utils/usePrefix';
import Button from 'components/_Redesign/Button';

import { DotSeparator } from '../ChatMeta/GeneralScreen/styles';
import { AdditionalUserInfoStyled, ChatCaseNumberContainer } from './styles';

const ReporterAdditionalInfo: React.FC<{
  reporterNameAndLastName?: string;
  reporterEmail?: string;
  reporterCreationTime?: string;
  chatCaseNumber?: string;
  showHistoryChanges?: () => void;
  showHistoryChangesIcon?: boolean;
}> = ({
  reporterNameAndLastName,
  reporterEmail,
  reporterCreationTime,
  chatCaseNumber,
  showHistoryChanges,
  showHistoryChangesIcon = false,
}) => {
  const t = usePrefix('Chat');
  const formattedReporterCreationTime =
    reporterCreationTime && dayjs(reporterCreationTime).format('YYYY-MM-DD HH:mm');

  return (
    <AdditionalUserInfoStyled>
      {reporterNameAndLastName || reporterEmail || formattedReporterCreationTime ? (
        <>
          {formattedReporterCreationTime || '-'}
          <DotSeparator />
          {reporterNameAndLastName || '-'}
          <DotSeparator />
          {reporterEmail || '-'}
        </>
      ) : (
        t('not_provided')
      )}
      {showHistoryChangesIcon && (
        <Button
          type="button"
          onClick={showHistoryChanges}
          icon="clockCounterClockwise"
          size="sm"
          color="text-primary"
          variant="text"
        />
      )}
      {chatCaseNumber && <ChatCaseNumberContainer>{chatCaseNumber}</ChatCaseNumberContainer>}
    </AdditionalUserInfoStyled>
  );
};

export default ReporterAdditionalInfo;
