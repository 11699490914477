import React, { FC, memo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { getApiUrl, ResponseError } from 'utils/api';
import { notificationTypes } from 'utils/constants';
import usePrefix from 'utils/usePrefix';
import Modal from 'components/Modal';
import FormField from 'components/FormField';
import InputField from 'components/InputField';
import FlexDiv from 'components/FlexDiv';
import { DetailsTitleStyled } from 'components/DetailsTitle/styles';
import ButtonHref from 'components/_Redesign/ButtonHref';

import { DateCell, DateRow, ModalContentWrapper, Separator } from './styles';

const url = getApiUrl('/interveners/me/chats');

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    date_from: Yup.date()
      .min('2020-01-01', ty('chat_min_date_from'))
      .max(Yup.ref('date_to'), ty('chat_max_date_from'))
      .required(),
    date_to: Yup.date()
      .min(Yup.ref('date_from'), ty('chat_min_date_to'))
      .max(dayjs().format('YYYY-MM-DD'), ty('chat_max_date_to'))
      .required(),
  });

interface FormValues {
  date_from: string;
  date_to: string;
}

interface Props {
  chatId: number;
  chatCreationTime?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ChatDownloadModal: FC<Props> = ({ chatId, chatCreationTime, onClose, isOpen }) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Chat');
  const ty = usePrefix('YupErrors');

  const initialValues: FormValues = {
    date_from: dayjs(chatCreationTime).format('YYYY-MM-DD'),
    date_to: dayjs().format('YYYY-MM-DD'),
  };

  const submit = async (values: FormValues) => {
    try {
      // eslint-disable-next-line no-console
      console.log(values);
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} paddingTop="24px">
      <ModalContentWrapper>
        <DetailsTitleStyled>{t('chat_download_title')}</DetailsTitleStyled>
        <Separator />
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={() => validationSchema(ty)}
          enableReinitialize={true}
        >
          {({ values, touched, errors }) => (
            <Form autoComplete="off">
              <DateRow>
                <DateCell>
                  <FormField label={t('chat_download_from')} margin="0">
                    <InputField
                      name="date_from"
                      error={touched.date_to && !!errors.date_to}
                      autoFocus
                      type="date"
                      dimension="lg"
                    />
                  </FormField>
                </DateCell>
                <DateCell>
                  <FormField label={t('chat_download_to')} margin="0">
                    <InputField
                      name="date_to"
                      error={touched.date_to && !!errors.date_to}
                      autoFocus
                      type="date"
                      dimension="lg"
                    />
                  </FormField>
                </DateCell>
              </DateRow>
              <Separator />
              <FlexDiv justifyContent="flex-end">
                <ButtonHref
                  href={`${url}/${chatId}/pdf?date_from=${values.date_from}&date_to=${values.date_to}`}
                  download
                  label={t('chat_download_button')}
                  color="primary"
                  size="lg"
                />
              </FlexDiv>
            </Form>
          )}
        </Formik>
      </ModalContentWrapper>
    </Modal>
  );
};

export default memo(ChatDownloadModal);
