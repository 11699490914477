import styled from 'styled-components';
import { Form } from 'formik';

import InputField from 'components/InputField';
import FormField from 'components/FormField';
import FlexDiv from 'components/FlexDiv';
import { CLOSE_ICON_SIZE_CSS } from 'components/Wrapper/constants';

export const Content = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 24px calc(${CLOSE_ICON_SIZE_CSS} * 2);
`;

export const ButtonsContainer = styled(FlexDiv)`
  column-gap: 24px;
`;

const INPUT_GROUP_WIDTH = '372px';

export const StyledFormField = styled(FormField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 24px;
`;

export const StyledInputField = styled(InputField)`
  width: ${INPUT_GROUP_WIDTH};
  margin-bottom: 24px;
`;

export const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 880px;
  margin-top: 24px;
`;
