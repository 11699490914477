import React, { useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { getApiUrl, PaginatedList } from 'utils/api';
import { useApi } from 'utils/api/useApi';
import { GetUsersResponse } from 'utils/api/users';
import usePrefix from 'utils/usePrefix';

import FlexDiv from 'components/FlexDiv';
import TableContainer from 'components/TableContainer';
import Row from 'components/Row';

import UserDetails from './User';

const url = getApiUrl('/users');

const RECORD_COUNT = 9999999;

export interface TableState {
  usersList: GetUsersResponse[];
  userId?: number;
}

export interface ModalState {
  isOpen: boolean;
  userId?: number;
}

const Users: React.FC = () => {
  const t = usePrefix('Users');
  const tg = usePrefix('General');

  const [modal, setModal] = useState<ModalState>({ isOpen: false });
  const [nameFilter, setNameFilter] = useState('');

  const MIN_QUERY_LENGTH = 3;

  const { data, isLoading, fetchData } = useApi<PaginatedList<GetUsersResponse[]>>(
    nameFilter.length < MIN_QUERY_LENGTH
      ? `${url}?page=0&size=${RECORD_COUNT}`
      : `${url}?name=${nameFilter}&page=0&size=${RECORD_COUNT}`,
    {
      method: 'GET',
    },
  );

  const handleFilterReset = () => {
    setNameFilter('');
  };

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex={1}
    >
      <TableContainer
        title={t('users')}
        isLoading={isLoading}
        isData={!!data?.content.length}
        setFilter={setNameFilter}
        labelLoader={t('fetching_users')}
        labelNoResults={t('no_users')}
        labelButton={t('add_user')}
        filter={nameFilter}
        setModal={setModal}
        resetFilter={handleFilterReset}
        isSearchClearDisabled={nameFilter.length < 1}
      >
        {!!data && (
          <AutoSizer disableWidth>
            {({ height }: { height: number }) => (
              <List
                height={height}
                width="100%"
                itemCount={data.content.length}
                itemSize={48}
                innerElementType="ul"
              >
                {({ index, ...props }) => (
                  <Row
                    title={`${data.content[index].first_name} ${data.content[index].last_name}`}
                    labels={[
                      `${data.content[index].first_name} ${data.content[index].last_name}`,
                      data.content[index].email,
                      tg(data.content[index].role),
                      data.content[index].is_active ? t('active') : t('blocked'),
                    ]}
                    onClick={() => setModal({ isOpen: true, userId: data.content[index].id })}
                    {...props}
                  />
                )}
              </List>
            )}
          </AutoSizer>
        )}
      </TableContainer>

      {!!data && modal.isOpen && (
        <UserDetails
          users={data.content}
          userId={modal.userId}
          onClose={() => setModal({ isOpen: false })}
          fetchData={fetchData}
          isOpen={modal.isOpen}
        />
      )}
    </FlexDiv>
  );
};

export default Users;
